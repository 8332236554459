import { render, staticRenderFns } from "./BoneCraftRisk.vue?vue&type=template&id=4c519580&scoped=true"
import script from "./BoneCraftRisk.vue?vue&type=script&lang=js"
export * from "./BoneCraftRisk.vue?vue&type=script&lang=js"
import style0 from "./BoneCraftRisk.vue?vue&type=style&index=0&id=4c519580&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c519580",
  null
  
)

/* custom blocks */
import block0 from "@/locales/bone_craft_risk.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fdental-implant%2Ftraditional-dental-implant%2Fbone-craft%2FBoneCraftRisk.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports